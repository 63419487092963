export const curses = [
  {
    price: '2930',
    name: 'Базовий',
    countOfLessons: '6',
    description: 'Чудово для початку',
  },
  {
    price: '5720',
    name: 'Стандарт',
    countOfLessons: '12',
    description: 'Те що треба для твоєї дитини',
  },
  {
    price: '10520',
    name: 'Продвинутий',
    countOfLessons: '24',
    description: 'Для повної впевненості',
  },
];
